"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAvailableAtForCustomerIo = exports.shouldNotBeNotified = exports.hasRecentUpdate = exports.getFreelanceRemainingDays = exports.getAvailabilityDate = exports.isAvailableAtAsDate = exports.isAvailableAtAsPeriod = exports.PERIOD_OPTIONS = exports.FREELANCE_SOON_AVAILABLE_DURATION_DAYS = exports.FREELANCE_AVAILABILITY_DURATION_DAYS = void 0;
const graphql_types_1 = require("@frk/graphql-types");
const helpers_1 = require("@frk/helpers");
const utils_core_1 = require("./utils.core");
exports.FREELANCE_AVAILABILITY_DURATION_DAYS = 14;
exports.FREELANCE_SOON_AVAILABLE_DURATION_DAYS = 60;
exports.PERIOD_OPTIONS = [
    { value: graphql_types_1.PeriodEnum.P0W, label: 'Aucun préavis', default: true },
    { value: graphql_types_1.PeriodEnum.P1W, label: '1 semaine' },
    { value: graphql_types_1.PeriodEnum.P2W, label: '2 semaines' },
    { value: graphql_types_1.PeriodEnum.P3W, label: '3 semaines' },
    { value: graphql_types_1.PeriodEnum.P1M, label: '1 mois' },
    { value: graphql_types_1.PeriodEnum.P2M, label: '2 mois' },
    { value: graphql_types_1.PeriodEnum.P3M, label: '3 mois' },
];
const isAvailableAtAsPeriod = (availableAt) => exports.PERIOD_OPTIONS.some(option => option.value === availableAt);
exports.isAvailableAtAsPeriod = isAvailableAtAsPeriod;
// TODO Take decision : custom date functions or dateUtils
const isAvailableAtAsDate = (availableAt) => {
    if (!availableAt) {
        return false;
    }
    const d = new Date(availableAt);
    return !Number.isNaN(d.getTime());
};
exports.isAvailableAtAsDate = isAvailableAtAsDate;
function getAvailabilityDate(marketSituation) {
    if (!marketSituation.availableAt) {
        return null;
    }
    // TODO Why is available date the updated date if the availability is a period ?
    if ((0, exports.isAvailableAtAsPeriod)(marketSituation.availableAt)) {
        return helpers_1.dateUtils.createDate(marketSituation.updatedAt).toDate();
    }
    if ((0, exports.isAvailableAtAsDate)(marketSituation.availableAt)) {
        return marketSituation.availableAt ? helpers_1.dateUtils.createDate(marketSituation.availableAt).toDate() : null;
    }
    return null;
}
exports.getAvailabilityDate = getAvailabilityDate;
function getFreelanceRemainingDays(marketSituation) {
    const availabilityDate = getAvailabilityDate(marketSituation);
    return availabilityDate
        ? helpers_1.dateUtils.diffDate(helpers_1.dateUtils.dateStartOf(availabilityDate, 'day'), helpers_1.dateUtils.dateStartOf(new Date(), 'day'), 'days') + exports.FREELANCE_AVAILABILITY_DURATION_DAYS
        : -1;
}
exports.getFreelanceRemainingDays = getFreelanceRemainingDays;
function hasRecentUpdate(marketSituation) {
    return helpers_1.dateUtils.isDateAfter(marketSituation.updatedAt, helpers_1.dateUtils.dateSubtract(new Date(), 15, 'days'));
}
exports.hasRecentUpdate = hasRecentUpdate;
function shouldNotBeNotified(marketSituation) {
    // ? shouldNotBeNotified = freelance is not Listening, not available within 30 days and has updated availability in the last 60 days
    // ? If marketSituation is not defined, if the freelance is listening or the freelance has defined a period, we should notify
    if (!marketSituation || marketSituation.isListening || (0, exports.isAvailableAtAsPeriod)(marketSituation.availableAt)) {
        return false;
    }
    const today = new Date();
    const sixtyDaysAgo = helpers_1.dateUtils.dateSubtract(today, 60, 'days').toDate();
    if (helpers_1.dateUtils.isDateBefore(marketSituation.updatedAt, sixtyDaysAgo)) {
        return false;
    }
    const thirtyDaysFromNow = helpers_1.dateUtils.dateAdd(today, 30, 'days').toDate();
    return !marketSituation.availableAt || helpers_1.dateUtils.isDateAfter(marketSituation.availableAt, thirtyDaysFromNow);
}
exports.shouldNotBeNotified = shouldNotBeNotified;
function formatAvailableAtForCustomerIo(availableAt) {
    if ((0, exports.isAvailableAtAsPeriod)(availableAt)) {
        const label = (0, utils_core_1.getLabelByValue)(exports.PERIOD_OPTIONS, availableAt);
        return `Disponible avec un préavis de ${label}`;
    }
    if ((0, exports.isAvailableAtAsDate)(availableAt)) {
        if (helpers_1.dateUtils.isDateBefore(availableAt, new Date())) {
            return 'Disponible immédiatement';
        }
        return `Disponible le ${helpers_1.dateUtils.formatDate(availableAt, 'DD/MM/YYYY')}`;
    }
    return 'Disponibilité non renseignée';
}
exports.formatAvailableAtForCustomerIo = formatAvailableAtForCustomerIo;
