var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isVisible)?_c('Portal',{attrs:{"disabled":!_setup.isVisible,"to":"modal"}},[_c('Transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"modal tw-z-100 tw-size-full tw-fixed tw-bottom-0 tw-left-0 tw-top-0 tw-flex tw-max-h-screen tw-w-screen tw-justify-center tw-overflow-x-hidden tw-overscroll-none tw-bg-slate-900 tw-bg-opacity-50 tw-p-4 tw-font-circular"},[_c('div',{staticClass:"tw-flex tw-max-h-full tw-w-full tw-flex-col md:tw-w-6/12",attrs:{"data-test":`${_vm.name}--FrkModal`}},[_c(_setup.FrkFlexContainer,{staticClass:"tw-rounded-t-xl tw-border-b tw-border-gray-200 tw-bg-white tw-px-8 tw-py-4 dark:tw-border-gray-700 dark:tw-bg-slate-800"},[_c('div',{staticClass:"tw-flex-auto"},[_vm._t("head",function(){return [_c(_setup.FrkFlexContainer,[_c('div',{staticClass:"tw-text-xl tw-font-bold tw-text-gray-800 dark:tw-text-white"},[_vm._t("title")],2),(_setup.$slots['help-text'])?_c(_setup.FrkHelpIcon,{scopedSlots:_vm._u([{key:"help-text",fn:function(){return [_vm._t("help-text")]},proxy:true}],null,true)}):_vm._e()],1)]})],2),(!_vm.hideCloseButton)?_c('div',{staticClass:"tw-flex-none"},[_c(_setup.GenericButton,{attrs:{"color":"grey-light","data-test":"CloseButton","icon":"","no-shadow":""},on:{"click":_setup.$modal.hide}},[_c('i',{staticClass:"fas fa-times"})])],1):_vm._e()]),_c('div',{staticClass:"tw-max-h-full tw-overflow-y-auto tw-overscroll-none",class:[
            'tw-bg-white',
            'tw-p-8',
            _setup.roundedBorderClass,
            'dark:tw-bg-slate-800',
            _vm.startingFromBottom ? 'tw-flex tw-flex-col-reverse' : '',
          ]},[_c('div',[_vm._t("body")],2)]),(_setup.$slots['foot'])?_c('div',{class:['tw-bg-gray-50', 'tw-px-8', 'tw-py-4', 'tw-text-right', 'tw-rounded-b-xl', 'dark:tw-bg-slate-800']},[_vm._t("foot")],2):_vm._e()],1)])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }