import Vue from 'vue';
import { ObserveVisibility } from 'vue-observe-visibility';
import Vuelidate from 'vuelidate';

import pkg from '@frk/version/package.json';

import clickoutDirective from '@frk/commons/ui/directives/clickout';

import AuthPlugin from '@frk/commons/ui/plugins/auth';
import Cloudinary from '@frk/commons/ui/plugins/cloudinary';
import Logger from '@frk/commons/ui/plugins/logger';
import Modal from '@frk/commons/ui/plugins/modal';
import FrkGooglePlaces from '@frk/commons/ui/plugins/places';
import Rudderstack from '@frk/commons/ui/plugins/rudderstack';
import SentryPlugin from '@frk/commons/ui/plugins/sentry';
import VTooltipPlugin from '@frk/commons/ui/plugins/v-tooltip';
import MetaPlugin from '@frk/commons/ui/plugins/vue-meta';

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

import { VueApollo } from '@frk/commons/ui/utils/vueApollo';

import { createApolloClient } from '@frk/commons/api/utils/apolloClient';
import { initSentryBrowser } from '@frk/commons/api/utils/sentryClient';

import darkMode from '@frk/commons/api/utils/darkMode';

import './assets/styles/tailwind.css';

import { createRouter } from './router';
import App from './ui/App.vue';
import Gtm from './ui/plugins/gtm';
import store from './ui/store';

const {
  NODE_ENV,
  VUE_APP_BASE_URL,
  VUE_APP_API_URL,
  VUE_APP_AUTH0_AUDIENCE,
  VUE_APP_AUTH0_ID,
  VUE_APP_AUTH0_DOMAIN,
  VUE_APP_CONFIG_ENV,
  VUE_APP_GOOGLE_API_KEY,
  VUE_APP_GTM_ID,
  VUE_APP_SENTRY_DSN,
} = process.env;

const sentry = initSentryBrowser({
  project: 'ui-app',
  version: pkg.version,
  environment: VUE_APP_CONFIG_ENV,
  dsn: VUE_APP_SENTRY_DSN,
  Vue,
});

const router = createRouter({ sentry });

Vue.config.productionTip = false;

Vue.directive('clickout', clickoutDirective);
Vue.directive('observe-visibility', ObserveVisibility);

// Vue config
Vue.use(Cloudinary);
Vue.use(MetaPlugin);
Vue.use(Modal, { Vue });
Vue.use(Gtm, {
  id: VUE_APP_GTM_ID,
  // Enable the GTM scripts only in production env
  enabled: VUE_APP_CONFIG_ENV === 'production',
  debug: false,
});
Vue.use(Logger);
Vue.use(darkMode);
Vue.use(Rudderstack);
Vue.use(SentryPlugin, { sentry });
Vue.use(VTooltipPlugin);
Vue.use(VueApollo);
Vue.use(Vuelidate);
Vue.use(Donut);
Vue.use(FrkGooglePlaces, {
  apiKey: VUE_APP_GOOGLE_API_KEY,
});

(async () => {
  const apolloClient = await createApolloClient({
    apiUri: VUE_APP_API_URL,
    NODE_ENV,
    sentry,
  });

  Vue.use(AuthPlugin, {
    apolloClient,
    auth0ClientOptions: { audience: VUE_APP_AUTH0_AUDIENCE, domain: VUE_APP_AUTH0_DOMAIN, client_id: VUE_APP_AUTH0_ID },
    defaultRedirectUri: `${VUE_APP_BASE_URL}/loading`,
    logoutReturnTo: VUE_APP_BASE_URL,
  });

  new Vue({
    router,
    store,
    apolloProvider: new VueApollo({
      defaultClient: apolloClient,
    }),
    render: h => h(App),
  }).$mount('#app');
})();
