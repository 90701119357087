// * Welcome to the Boryl Tracking Plan
// ? We did not design this API and were asked to implement it as is
// ! The Boryl API Schema is questionable and is not in sync with our Nomenclature !

import { dateUtils } from '@frk/helpers';
import { PERIOD_OPTIONS, isAvailableAtAsDate, isAvailableAtAsPeriod } from '@frk/models-core/MarketSituation.core';
import { ACTIVITY_OPTIONS } from '@frk/models-core/Freelance.core';
import { MISSION_DURATION_OPTIONS } from '@frk/models-core/Mission.core';
import { ConstantOption, getLabelByValue } from '@frk/models-core/utils.core';
import { MarketSituationInput } from '@frk/graphql-types';

// ? These types could be smarter to prevent invalid combinations...
// ! but I decided the implementation cost is too high compared to its value
export type BorylTrackingEventBaseSchema = {
  event: 'funnel' | 'form';
  event_category: 'funnel_seek_freelance' | 'funnel_seek_mission' | 'form';
  event_action: 'step1' | 'step2' | 'step3' | 'submit';
  event_label:
    | 'description'
    | 'availablity'
    | 'informations'
    | 'seek_freelance'
    | 'description'
    | 'situation'
    | 'informations'
    | 'seek_mission';
  event_name:
    | 'funnel_seek_freelance_step1'
    | 'funnel_seek_freelance_step2'
    | 'funnel_seek_freelance_step3'
    | 'form_seek_freelance_submitted'
    | 'funnel_seek_description_step1'
    | 'funnel_seek_mission_step2'
    | 'funnel_seek_mission_step3'
    | 'form_seek_mission_submitted';
};

// TODO Type this from the first use store;
type FirstUseDataPath = string;
type FirstUseDataTransformFn = (
  value: // ? options
  | string
    // ? starting date
    | Date
    // ? availability
    // TODO Fix this type : this is a partial availability without updatedAt
    | MarketSituationInput
) => string | null;

function transformDate(date: Date): string {
  return dateUtils.formatDate(date, 'YYYY-MM-DD');
}

function transformFromOptions(options: ConstantOption[]) {
  return function transform(value: string): string {
    return getLabelByValue(options, value);
  };
}

export type BorylTrackingEventAdditionalData = {
  profile?: FirstUseDataPath;
  skills?: FirstUseDataPath;
  seek_freelance_starting_date?: {
    path: FirstUseDataPath;
    transform: FirstUseDataTransformFn;
  };
  seek_freelance_budget?: FirstUseDataPath;
  seek_freelance_duration?: {
    path: FirstUseDataPath;
    transform: FirstUseDataTransformFn;
  };
  seek_freelance_role?: FirstUseDataPath;
  seek_mission_status?: {
    path: FirstUseDataPath;
    transform: FirstUseDataTransformFn;
  };
  seek_mission_availability_date?: {
    path: FirstUseDataPath;
    transform: FirstUseDataTransformFn;
  };
  seek_mission_price?: FirstUseDataPath;
  seek_mission_notice_period?: {
    path: FirstUseDataPath;
    transform: FirstUseDataTransformFn;
  };
};

export type BorylTrackingEvent = BorylTrackingEventBaseSchema & BorylTrackingEventAdditionalData;

export type BorylPlanId = 'seek_freelance' | 'seek_mission';

const seekFreelanceStep2: BorylTrackingEventAdditionalData = {
  profile: 'firstMission.profile',
  skills: 'firstMission.skills',
};

const seekFreelanceStep3: BorylTrackingEventAdditionalData = {
  seek_freelance_starting_date: {
    path: 'firstMission.startingDate',
    transform: transformDate,
  },
  seek_freelance_budget: 'firstMission.price',
  seek_freelance_duration: {
    path: 'firstMission.duration',
    transform: transformFromOptions(MISSION_DURATION_OPTIONS),
  },
};

const seekFreelanceForm: BorylTrackingEventAdditionalData = {
  seek_freelance_role: 'client.title',
};

const seekMissionStep2: BorylTrackingEventAdditionalData = {
  profile: 'freelance.title',
  skills: 'freelance.skills',
};

const seekMissionStep3: BorylTrackingEventAdditionalData = {
  seek_mission_status: {
    path: 'freelance.activity',
    transform: transformFromOptions(ACTIVITY_OPTIONS),
  },
  seek_mission_availability_date: {
    path: 'freelance.marketSituation',
    transform: (marketSituation: MarketSituationInput) => {
      if (isAvailableAtAsDate(marketSituation.availableAt)) {
        return transformDate(dateUtils.createDate(marketSituation.availableAt).toDate());
      }

      return null;
    },
  },
  seek_mission_notice_period: {
    path: 'freelance.marketSituation',
    transform: (marketSituation: MarketSituationInput) => {
      if (isAvailableAtAsPeriod(marketSituation.availableAt)) {
        const transformFn = transformFromOptions(PERIOD_OPTIONS);

        return transformFn(marketSituation.availableAt);
      }

      return null;
    },
  },
  seek_mission_price: 'freelance.price',
};
const seekMissionForm: BorylTrackingEventAdditionalData = {};

export const borylPlans: Record<BorylPlanId, BorylTrackingEvent[]> = {
  seek_freelance: [
    {
      event: 'funnel',
      event_category: 'funnel_seek_freelance',
      event_action: 'step1',
      event_label: 'description',
      event_name: 'funnel_seek_freelance_step1',
    },
    {
      event: 'funnel',
      event_category: 'funnel_seek_freelance',
      event_action: 'step2',
      event_label: 'availablity',
      event_name: 'funnel_seek_freelance_step2',
      ...seekFreelanceStep2,
    },
    {
      event: 'funnel',
      event_category: 'funnel_seek_freelance',
      event_action: 'step3',
      event_label: 'informations',
      event_name: 'funnel_seek_freelance_step3',
      ...seekFreelanceStep2,
      ...seekFreelanceStep3,
    },
    {
      event: 'form',
      event_category: 'form',
      event_action: 'submit',
      event_label: 'seek_freelance',
      event_name: 'form_seek_freelance_submitted',
      ...seekFreelanceStep2,
      ...seekFreelanceStep3,
      ...seekFreelanceForm,
    },
  ],
  seek_mission: [
    {
      event: 'funnel',
      event_category: 'funnel_seek_mission',
      event_action: 'step1',
      event_label: 'description',
      event_name: 'funnel_seek_description_step1',
    },
    {
      event: 'funnel',
      event_category: 'funnel_seek_mission',
      event_action: 'step2',
      event_label: 'situation',
      event_name: 'funnel_seek_mission_step2',
      ...seekMissionStep2,
    },
    {
      event: 'funnel',
      event_category: 'funnel_seek_mission',
      event_action: 'step3',
      event_label: 'informations',
      event_name: 'funnel_seek_mission_step3',
      ...seekMissionStep2,
      ...seekMissionStep3,
    },
    {
      event: 'form',
      event_category: 'form',
      event_action: 'submit',
      event_label: 'seek_mission',
      event_name: 'form_seek_mission_submitted',
      ...seekMissionStep2,
      ...seekMissionStep3,
      ...seekMissionForm,
    },
  ],
};
