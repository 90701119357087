import debounce from 'lodash.debounce';
import objectPath from 'object-path';

import { Availability } from '@frk/models-core/MarketSituation.core';
import { clone } from '@frk/helpers';

import { BorylPlanId, borylPlans } from './borylTrackingPlan';
import legacyEvents from './legacyEvents';

// TODO Type the first use store correctly
type FirstUseDataStore = { [key: string]: string | string[] | null | Availability };

declare module 'vue/types/vue' {
  type GtmPlugin = typeof gtm;

  interface VueConstructor {
    $gtm: GtmPlugin;
  }

  interface Vue {
    $gtm: GtmPlugin;
  }
}

declare global {
  interface Window {
    dataLayer: Record<string, any>;
  }
}

type LegacyDatalayerPayload = {
  event?: string;
} & Record<string, any>;

interface LegacyGtmEvent {
  action: string;
  category: string;
  label: string;
}

window.dataLayer = window.dataLayer || [];

const defaultCfg = {
  id: undefined,
  enabled: false,
  debug: false,
};
let config = defaultCfg;
const { dataLayer } = window;
const gtmVars = {
  user: {},
  freelance: {},
  company: {},
};

let synced = false;

const log = ({ funcName = '', msg = '' }) => {
  if (config.debug) {
    // eslint-disable-next-line
    console.log(`[gtm:${funcName}()] ${msg}`);
  }
};

/**
 * Load GTM script tag
 *
 * @param id {string} GTM ID
 */
const loadScript = (id: string) => {
  const script = document.createElement('script');

  dataLayer.push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
  });
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;
  document.body.appendChild(script);
};

const sendVars = debounce(() => {
  dataLayer.push({
    event: 'varsContent',
    vars: gtmVars,
  });
}, 250);

const gtm = {
  isSynced() {
    log({
      funcName: 'isSynced',
      msg: `event: varsContent, vars: ${JSON.stringify(gtmVars)}, synced: ${synced}`,
    });
    sendVars();

    if (!synced) {
      synced = true;
      dataLayer.push({
        event: 'dataLayerReady',
      });
    }
  },
  /**
   * @param url {string}
   * @param title {string}
   * @param hasData {boolean}
   */
  trackPageView(url: string, title: string, hasData: boolean) {
    // eslint-disable-next-line
    console.assert(typeof hasData === 'boolean', 'Invalid param type hasData - Must be a boolean');
    // Skip Loading page to simplify Google Analytics funnels
    if (url.startsWith('/loading')) {
      return;
    }
    log({ funcName: 'trackPageView', msg: `url: ${url}, title: ${title}, hasData: ${hasData}` });
    dataLayer.push({
      event: 'virtualPageView',
      virtualPageURL: url,
      virtualPageTitle: title,
      hasData,
    });
  },
  trackUserVar(name: string, value: string) {
    log({ funcName: 'trackUserVar', msg: `name: ${name}, value: ${value}` });
    gtmVars.user[name] = value;
    sendVars();
  },
  trackFreelanceVar(name: string, value: string) {
    log({ funcName: 'trackFreelanceVar', msg: `name: ${name}, value: ${value}` });
    gtmVars.freelance[name] = value;
    sendVars();
  },
  trackCompanyVar(name: string, value: string) {
    log({ funcName: 'trackCompanyVar', msg: `name: ${name}, value: ${value}` });
    gtmVars.company[name] = value;
    sendVars();
  },
  trackLegacyEvent(arg: string | LegacyGtmEvent) {
    const event: LegacyGtmEvent = typeof arg === 'string' ? legacyEvents[arg] : arg;

    if (!event) {
      throw new Error(`Invalid GTM event ${arg} is incorrect`);
    }

    const data: LegacyDatalayerPayload = {
      event: 'eventGA',
      eventCategory: event.category,
      eventAction: event.action,
    };

    if (event.label) {
      data.eventLabel = event.label;
    }
    log({ funcName: 'trackLegacyEvent', msg: `data: ${JSON.stringify(data)}` });
    dataLayer.push(data);
  },
  legacyEvents,
  trackBorylPlan(planId: BorylPlanId, sequence: number, firstUseDataStore?: FirstUseDataStore) {
    const plan = borylPlans[planId];

    if (sequence > plan.length) {
      throw new Error('Attempt to track an event out of the Boryl plan !');
    }

    // ! Clone before mutating the event plan
    // ? It could lead to troubles if going back and force through the history
    const event = clone(plan[sequence]);

    // TODO Make our UI able to use @frk/logger (commons/api/utils/logger)
    if (process.env.NODE_ENV === 'development') {
      console.log(event);
    }

    if (firstUseDataStore) {
      Object.entries(event)
        .filter(([eventKey]) => !eventKey.startsWith('event'))
        .forEach(([eventDataKey, eventDataValue]) => {
          // The event data value matches a first use data path
          if (typeof eventDataValue === 'string' || Array.isArray(eventDataValue)) {
            event[eventDataKey] = objectPath.get(firstUseDataStore, eventDataValue);
          } else if (eventDataValue === null) {
            event[eventDataKey] = eventDataValue;
          } else {
            const { path, transform } = eventDataValue;
            const firstUseValue = objectPath.get(firstUseDataStore, path);
            const transformedValue = transform(firstUseValue);

            event[eventDataKey] = transformedValue;
          }
        });
    }

    dataLayer.push(event);
  },
};

const install = (Vue, initCfg = {}) => {
  config = { ...defaultCfg, ...initCfg };

  Vue.$gtm = gtm;
  Vue.prototype.$gtm = gtm;

  // Load GTM script when enabled
  if (config.enabled) {
    loadScript(config.id);
  }
};

export default {
  install,
};
